import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(authenticated)/(homepage)": [~14,[3]],
		"/(authenticated)/(users)/(routes)/admin": [34,[2]],
		"/(authenticated)/(dashboard)/(routes)/admin/billing": [~11,[2]],
		"/(authenticated)/(cart)/(routes)/admin/cart": [~6,[2]],
		"/(authenticated)/(cart)/(routes)/admin/cart/[buyerId]": [~7,[2]],
		"/(authenticated)/(config)/admin/config": [~10,[2]],
		"/(authenticated)/(dashboard)/(routes)/admin/dashboard": [~12,[2]],
		"/(authenticated)/(sales-manager)/(routes)/admin/salesmanagers": [~28,[2]],
		"/(authenticated)/(sales-manager)/(routes)/admin/salesmanagers/[salesManagerId]": [~29,[2]],
		"/(authenticated)/(users)/(routes)/admin/users": [~35,[2]],
		"/(authenticated)/(users)/(routes)/admin/users/[userId]": [~36,[2]],
		"/(unauthenticated)/auth/login": [~41,[5]],
		"/(unauthenticated)/auth/reset-password/[token]": [~42,[5]],
		"/(authenticated)/(cart)/(routes)/buyer/cart": [~8,[2]],
		"/(authenticated)/(orders)/(routes)/buyer/ordergroup": [~15,[2]],
		"/(authenticated)/(orders)/(routes)/buyer/ordergroup/[ordergroupid]": [~16,[2]],
		"/(authenticated)/(cart)/(routes)/cart/[cod_prodotto]": [9,[2]],
		"/(authenticated)/(products)/(routes)/catalog": [~23,[2]],
		"/(authenticated)/(orders)/(routes)/customerService/order": [~17,[2]],
		"/(authenticated)/(orders)/(routes)/customerService/order/[orderId]": [~18,[2]],
		"/(authenticated)/(seller-filters)/(routes)/customerService/seller-filters": [~30,[2,4]],
		"/(authenticated)/(seller-filters)/(routes)/customerService/seller-filters/[sellerId]": [~31,[2,4]],
		"/(authenticated)/(orders)/(routes)/order": [19,[2]],
		"/(authenticated)/(orders)/(routes)/order/[orderId]": [~20,[2]],
		"/(authenticated)/(users)/(routes)/pharmacy": [37,[2]],
		"/(authenticated)/(users)/(routes)/pharmacy/[pharmacyId]": [~38,[2]],
		"/(authenticated)/(products)/(routes)/product": [24,[2]],
		"/(authenticated)/(products)/(routes)/product/[codprodotto]": [~25,[2]],
		"/(authenticated)/(users)/(routes)/profile": [~39,[2]],
		"/(unauthenticated)/registration": [~43],
		"/(authenticated)/(orders)/(routes)/seller/order": [~21,[2]],
		"/(authenticated)/(dashboard)/(routes)/seller/order/stats": [~13,[2]],
		"/(authenticated)/(orders)/(routes)/seller/order/[orderId]": [~22,[2]],
		"/(authenticated)/(products)/(routes)/seller/product": [~26,[2]],
		"/(authenticated)/(products)/(routes)/seller/product/[productId]": [~27,[2]],
		"/(authenticated)/(users)/(routes)/seller/settings": [~40,[2]],
		"/(authenticated)/(support)/(routes)/support": [~32,[2]],
		"/(authenticated)/(support)/(routes)/support/[ticketId]": [~33,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';